import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import DailyTransactionListPdf from "@/components/page/daily-transaction-list-pdf";
import moment from "moment";
import MixinsFuntions from "@/service/MixinsFuntions";
import axios from "axios";
export default {
  mixins: [MixinsFuntions],
  name: "DailyTransaction",
  components: { DailyTransactionListPdf },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      identifier: {
        userJobtitleId: "",
        pdf: {
          dateFrom: "",
          dateTo: "",
        },
      },
      property: {
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
        listElement: {
          dailyTransaction: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
        modal: {
          showModalPrintDailyHistory: false,
          dataModal: "",
        },
      },
      dataForm: {
        userId: "",
        userName: "",
        userCode: "",
        date: "",
        officeName: "",
        dateFrom: "",
        dateTo: "",
        transactionDate: "",
      },
      options: {
        user: [],
      },
      table: {
        headers: {
          dailyTransaction: [],
        },
        data: {
          dailyTransaction: [],
        },
      },
    };
  },
  methods: {
    Moment: moment,
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.dailyTransactionListPdfRef.onOpenPreview({
        tableData: this.table.data.dailyTransaction,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          userName: this.dataForm.userName,
          officeName: this.dataForm.officeName,
          filteredDate: this.dataForm.transactionDate
            ? this.dataForm.transactionDate
            : `${moment().format("DD-MM-YYYY")} `,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },
    async getSystemDate() {
      this.dataForm.dateFrom = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.dateTo = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.helperGetDailyTransactionDataTable();
    },
    handleCatchErrorDailyTransaction(error) {
      console.log(error.response);
      this.table.data.dailyTransaction = [];
      this.property.listElement.dailyTransaction.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleChangeDateFromDailyTransaction(event) {
      this.table.data.dailyTransaction = [];
      this.property.listElement.dailyTransaction.downloading = true;
      this.dataForm.exportContent = "";
      this.dataForm.exportToExcel.data = [];
      this.dataForm.exportToExcel.header = [];
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/daily-transaction",
            payload: {
              userId: this.dataForm.userId,
              dateFrom: event,
              dateTo: this.dataForm.dateTo,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.dailyTransaction = resp.data.data;
              this.setUpDateFromDateTo(resp);
            } else {
              this.property.listElement.dailyTransaction.message =
                resp.data.message;
            }
          }, timeout);
        } catch (error) {
          setTimeout(() => {
            this.handleCatchErrorDailyTransaction(error);
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.dailyTransaction.downloading = false;
          }, timeout);
        }
      }
    },
    setUpDateFromDateTo(resp) {
      this.identifier.pdf.dateFrom = this.dateToYearMonthDay(
        resp.data.data[0].transactionDate
      );
      this.identifier.pdf.dateTo = this.dateToYearMonthDay(
        resp.data.data[resp.data.data.length - 1].transactionDate
      );
    },
    async handleChangeDateToDailyTransaction(event) {
      this.table.data.dailyTransaction = [];
      this.property.listElement.dailyTransaction.downloading = true;
      this.dataForm.exportContent = "";
      this.dataForm.exportToExcel.data = [];
      this.dataForm.exportToExcel.header = [];
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/daily-transaction",
            payload: {
              userId: this.dataForm.userId,
              dateFrom: this.dataForm.dateFrom,
              dateTo: event,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.dailyTransaction = resp.data.data;
              this.setUpDateFromDateTo(resp);
            } else {
              this.property.listElement.dailyTransaction.message =
                resp.data.message;
            }
          }, timeout);
        } catch (error) {
          setTimeout(() => {
            this.handleCatchErrorDailyTransaction(error);
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.dailyTransaction.downloading = false;
          }, timeout);
        }
      }
    },
    async helperGetDailyTransactionDataTable() {
      this.table.data.dailyTransaction = [];
      this.property.listElement.dailyTransaction.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/daily-transaction",
          payload: {
            userId: this.dataForm.userId,
            dateFrom: this.dataForm.dateFrom,
            dateTo: this.dataForm.dateTo,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.dailyTransaction = resp.data.data;
            this.dataForm.transactionDate = resp.data.data.transactionDate;
            this.setUpDateFromDateTo(resp);
          } else {
            this.property.listElement.dailyTransaction.message =
              resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDailyTransaction(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.dailyTransaction.downloading = false;
        }, timeout);
      }
    },
    helperGetUSerDataFromSession() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.dataForm.userName = getUserPayloadFromSession.userName;
      this.dataForm.userId = axios.defaults.headers.common["x-user-id"];
      this.dataForm.userCode = getUserPayloadFromSession.userCode;
      this.dataForm.officeName = getUserPayloadFromSession.officeName;
      this.identifier.userJobtitleId = getUserPayloadFromSession.userJobTitleId;
    },
    getDateFormat(valDate) {
      var date = new Date(valDate);

      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    showModalPrint(props) {
      this.property.modal.showModalPrintDailyHistory = true;
      this.property.modal.dataModal = props.row;
      console.log(props);
    },
    closeModalPrint() {
      this.property.modal.showModalPrintDailyHistory = false;
    },
    async cetakSlipTrx() {
      let pdf = new jsPDF("landscape", "mm", "a4");

      setTimeout(() => {
        html2canvas(document.querySelector("#table-slip-detail"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-slip-detail").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 21, 9);
          pdf.autoPrint();

          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-slip-detail").innerHTML = "";
          // this.property.modal.showModalPrintSavingAccountDetail = false;
          this.property.modal.showModalPrintDailyHistory = false;
        });
      }, 200);
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    moment.locale("ID");
    this.helperGetUSerDataFromSession();
    this.getSystemDate();
  },
};
